import 'animate.css/animate.css';
import '../styles/template.scss';

import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
// import slide1 from "../images/images/slide1.png"
// import downarrow from "../images/images/downarrow.svg"
import React, { useEffect, useState } from 'react';
import Rellax from 'rellax';
import ScrollOut from 'scroll-out';

import BubbleTestimony from '../components/BubbleTestimony';
import FaqSection from '../components/faq';
import FooterCommon from '../components/footer-common';
import InsuranceLearnMore from '../components/InsuranceLearnMore';
import PartnerLogo from '../components/PartnerLogos';
import SEO from '../components/seo';
import SmartAssurance from '../components/SmartAssurance';
import bubbleemailer from '../images/bubble-emailer-final-3.png';
import bubbleemailersmall from '../images/bubble-emailer-small.png';
import call from '../images/call-24-px.png';
import email from '../images/emailnew.png';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.png';
import linkedin from '../images/linked-in.svg';
import oval1 from '../images/oval1.svg';
import oval2 from '../images/oval2.svg';
import oval3 from '../images/oval3.svg';
import oval4 from '../images/oval4.svg';
import twitter from '../images/twitter.svg';
import * as headerStyles from '../styles/common.module.css';
import AddressWrapper from './addressWrapper';
import MenuPartner from './menu';
import MenuMobilePartner from './menu-mobile';
import VerticalSlider from './VerticalSlider';

// const Bubbletonerbanner = 'https://cdn.getmybubble.com/images/corporate-sites/Bubble_Emailer.webp';
const Bubbletonerbanner = 'https://cdn.getmybubble.com/images/corporate-sites/Text_and_illustration.webp';


const HomePageBlogs = loadable( () => import( '../components/homePageBlogs' ) );

function Template( { pageData } ) {

	const [mobile, isMobile] = useState( false );
	const [modal, modalState] = useState( false );

	const data = useStaticQuery(
		graphql`query {
      allStrapiArticle(filter: {page_name: {eq: "home_page"}, status: {eq: "Production"}, data_category: {eq: "did_you_know"}}) {
        edges {
          node {
            body
            id
            title
          }
        }
      }
      licenseNumbers: allStrapiPageData(filter: {page_name: {eq: "license_numbers"}}) {
        edges {
          node {
            data_category
            column
            content
            style_category
            attribute_1_value
            attribute_2_value
            attribute_1_key
            attribute_2_key
          }
        }
      }
      allStrapiUser {
        edges {
          node {
            email
            username
            role {
              name
            }
          }
        }
      }
    }` );

	const faqArray = [
		{
			question: 'What are the advantages of buying home and life insurance together?',
			answer: 'You need home insurance to protect your home and your personal belongings. Life insurance does the same for your family when you are not around anymore. Buying them together means being prepared for any contingency.'
		},
		{
			question: ' What if I just need one of either life or home insurance?',
			answer: 'Sure, you can buy either one. But we strongly advise you to bundle them together for well-rounded protection for your loved ones.'
		},
		{
			question: 'Can I buy only term policies at Bubble?',
			answer: 'At present you can only buy term policies at Bubble. We help you find the right term policy that you’re comfortable with and one that fits your budget. In future, we shall be offering many more products.'
		},
		{
			question: 'How does Life Insurance fit in with my household\'s other financial responsibilities?',
			answer: 'Our smart guidance platform takes into account your other financial responsibilities, your family circumstances and other factors to help you find the most appropriate coverage and term for your policy. The payout can help your family with mortgage payments, or children\'s college tuition, and they can be financially secure even when you are not there anymore. Plan your expenses right and you will be glad to have bought a policy.'
		},
		{
			question: 'Does everybody really need a medical test to get life insurance?',
			answer: 'No. In fact, Bubble partner carriers provide accelerated, fluidless, no-exam underwriting for close to half the applicants in the 25-45 age group.'
		},
		{
			question: 'What does Home Insurance cover?',
			answer: 'Home insurance helps pay for lost or stolen personal belongings and for repairs or rebuilding your home in case of any damage caused by perils like theft, fire, flood, and so on. It may also cover hotel stay if your home is too damaged to live in.'
		},
		{
			question: 'Is it legally mandatory to buy Home Insurance?',
			answer: 'Legally, it is possible to own a home without home insurance, but your mortgage provider may require that you buy it to protect their financial interests.'
		},
		{
			question: ' Is it too complicated and a lengthy process to buy home or life insurance?',
			answer: 'It used to be a complicated process years ago. Buying has now become simple, fast and easy with the help of technology and user journeys designed by companies, such as Bubble. '
		}
	];

	useEffect( () => {
		new Rellax( '.rellax', { // <---- Via class name
			speed: 5
		} );
	}, [] );


	let innerWidth = 992;
	// const { innerWidth } = window;
	if ( typeof window !== 'undefined' ) {
		innerWidth = window.innerWidth;
	}

	function modalChanged() {
		modalState( !modal );
	}

	useEffect( () => {
		if ( innerWidth < 991 ) {
			isMobile( true );
		}
	}, [innerWidth] );

	useEffect( () => {
		ScrollOut( {
			threshold: .5,
			once: false,
			cssProps: {
				viewportY: true,
				visibleY: true
			}
		} );
	}, [] );

	// useEffect( () => {
	//   setPageData( data.pageData?.edges?.[0]?.node || {} );
	// }, [data] );

	function contactUs( e ) {
		e.preventDefault();
		const contact = document.querySelector( '#contactUs' );

		if( contact ) {
			contact.click();
		} else {
			modalState( !modal );
		}
	}


	return (
		<div className='partners'>

			<SEO
				title="Bundled Home and Life"
				description="Digitally bundled life and home insurance for homeowners to protect their homes and their loved ones! Visit now to see how little it costs."
				keywords="Bundled Insurance Plans Life and Home Insurance Best Bundle Insurance"
				canonicalUrl={'https://www.getmybubble.com/' + pageData.page_name.toLowerCase() + '/'}
			/>

			<script>
        var rellax = new Rellax('.rellax');
        ScrollOut();
			</script>

			{/* {
        mobile
          ? */}

			<MenuMobilePartner
				modalOpen={modal}
				modalChanged={modalChanged}
				partner={true}
				url={pageData.attribute_3_value}
				logo={pageData.attribute_4_value}
				alt={pageData.attribute_2_value}/>

			{/* : */}

			<MenuPartner
				url={pageData.attribute_3_value}
				logo={pageData.attribute_4_value}
				alt={pageData.attribute_2_value}/>

			{/* } */}

			<div  className="wrapper">
				{/* banner section */}

				<div className={headerStyles.mainBannerWrapper + ' toner-header1 templatebox'}>
					<div className="container-fluid">

						<div className="row justify-content-center d-flex">

							<div className="banner-right-bar">
								<div className="pt-0 pb-0 banner-text">
									<h2 className={headerStyles.hpMainHeader} data-scroll>

										{
											pageData.attribute_2_value ?
												<> <span className="font-weight-bold">{ pageData.attribute_2_value }</span> and Bubble come together to offer </> :
												<> Bubble offers </>
										}
                                        customers the right Home, Auto, and Life Insurance policies. </h2>

									<h4 className={headerStyles.hpSecondHeader} data-scroll>Smart Insurance. Total Assurance.</h4>
								</div>

								<AddressWrapper parentPage={pageData.attribute_3_key} transparent="transparent" height="220px" margins="0px 0px 20px"/>
							</div>

							<div className="banner-left-bar pt-0 pb-0 text-center p0">
								<img src={Bubbletonerbanner} alt={pageData.attribute_2_value} className="img-fluid" />
							</div>

						</div>

					</div>
				</div>

				{/* second part div struct */}

				{/* third part div struct */}

				<InsuranceLearnMore/>

				{
					pageData.attribute_1_value === 'DO_NOT_SHOW' ? <></> :
						<div className="toner-getin-touch-section">
							<div className="container common-container-space">
								<div className="toner-getin-touch-box">
									<div className="toner-getin-touch-innerbox1">
										<img src={ pageData.attribute_4_key } alt={pageData.attribute_2_value} />

										<div>{ pageData.attribute_1_value ? <a className="basic" href={`tel:${pageData.attribute_1_value}`}>
											<img src={call} alt="call" style={{ margin: '0 5px 8px 0' }}/>

											{pageData.attribute_1_value}
										</a> : ''}</div>

										<div>{ pageData.attribute_1_key?.includes( '@' ) ?   <a className="basic" href={`mailto:${pageData.attribute_1_key}`}> <img src={email} alt="email" style={{ margin: '0 5px 0 0' }}/>

											{pageData.attribute_1_key}
										</a> : ''}</div>

										<ul>
											{ pageData.attribute_5_key && <li> <a href={pageData.attribute_5_key} target="_blank"  rel="noreferrer"><img src={facebook} alt="fb" /></a></li>
											}

											{ pageData.attribute_6_key && <li> <a href={pageData.attribute_6_key} target="_blank"  rel="noreferrer"><img src={instagram} alt="insta" /></a></li>}

											{ pageData.attribute_6_value && <li> <a href={pageData.attribute_6_value} target="_blank"  rel="noreferrer"><img src={twitter} alt="twit"/></a></li>}

											{ pageData.attribute_5_value && <li> <a href={pageData.attribute_5_value} target="_blank"  rel="noreferrer"><img src={linkedin} alt="linkedin" /></a></li>}

										</ul>

										<p dangerouslySetInnerHTML={{ __html: pageData.content }}></p>


										<a href={ pageData.attribute_1_key?.includes( '@' ) ? `mailto:${ pageData.attribute_1_key }` :  pageData.attribute_1_key} className="getstart-btn">Get In Touch</a>
									</div>

									<div className="toner-getin-touch-innerbox2">
										<a><img src={bubbleemailer} alt="email" /></a>
									</div>
								</div>
							</div>

							<img src={oval1} alt={pageData.attribute_2_value} className="toner-bgbtleftsmall rellax" data-rellax-speed="2" />

							<img src={oval2} alt={pageData.attribute_2_value}  className="toner-bgtoprtsmall rellax" data-rellax-speed="2" />

							<img src={oval3} alt={pageData.attribute_2_value}  className="toner-bgbtrtsmall rellax" data-rellax-speed="2" />

							<img src={oval4} alt={pageData.attribute_2_value}  className="toner-mobfirst rellax" data-rellax-speed="2" />

						</div>
				}

				<div id="scrollDiv" className="vertical-slidebox">
					<div className="container common-container-space">
						<div className="row">
							<div className="col-12 text-center">
								<h2 className="slide-head" data-scroll> See why Home and <br />Life Insurance belong together</h2>
							</div>
						</div>
					</div>

					<VerticalSlider allStrapiArticle={data.allStrapiArticle.edges} />
				</div>


				<div className="container common-container-space">
					<div className="toner-getin-touch-section2">
						<div>
							<a><img src={bubbleemailersmall} alt="email" /></a>
						</div>

						<div>
							<h4>Got any questions?</h4>

							<p>For any insurance-related queries, get in touch and we'll help you</p>

							{/* <Link to="/" className="getstart-btn">Get In Touch</Link> */}

							<a onClick={contactUs} className="getstart-btn" style={{ cursor:'pointer' }}>Get In Touch</a>
						</div>
					</div>
				</div>

				<BubbleTestimony/>

				<SmartAssurance/>

				{/* fourth part div struct */}

				<PartnerLogo/>

				{/* FAQ section */}

				<FaqSection  faqs={faqArray}/>

				{/* Blog section */}

				<div className="blog-box">
					<div className="container common-container-space">
						{/* style={{"padding":"0 6.9135%"}} */}

						<div className="row">
							<div className="col-12 text-center">
								<h2>Check out Bubble’s insights</h2>
							</div>

							<div className="col-12" >
								<div className="card-deck">
									<HomePageBlogs />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <BarnstableFooter/> */}

			<FooterCommon>
				{pageData.attribute_2_value ?	<p> { pageData.attribute_2_value } offers insurance policies through our partner at Bubble Insurance Solutions </p> : <></> }
			</FooterCommon>
		</div>
	);
};

export default Template;
